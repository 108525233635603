import React, { useState } from "react";
import { connect } from "react-redux";
import { changePassword } from "store/action/user";
import Alert from "components/Alert";
import { Link } from "react-router-dom";
import NurlineLogo from "static/images/nurline_logo.png";

const ResetPassword = ({ changePassword, userReducer }) => {
  const [password, setPassword] = useState(" ");
  const [verifyPassword, setVerifyPassword] = useState("");

  const onsubmit = e => {
    const token = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    e.preventDefault();
    changePassword({ password, token });
  };
  const passwordValidation = (email, verify) => {
    return email === verify ? false : true;
  };
  return (
    <>
      <div className="body-pd" id="body-pd">
        <header className="body-pd header" id="header">
          <div className="header_img">
            <img src={NurlineLogo} alt="" />
          </div>
        </header>
      </div>
      <div className="col-xl-12 col-12">
        <Alert />

        <div className="forgot-password">
          <div className="card text-center">
            {!userReducer.isPasswordChanged ? (
              <div className="card-body">
                <h6 className="mb-0 text-center">Almost Done!</h6>
                <h6 className="mb-0 text-center">
                  Please enter your new password and click on submit.
                </h6>

                <form onSubmit={e => onsubmit(e)}>
                  <input
                    type="password"
                    name="password"
                    className="form-control mb-2 mt-2"
                    placeholder="Password"
                    required
                    autoFocus
                    onChange={e => setPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    name="verifyPassword"
                    className="form-control mb-2 mt-2"
                    placeholder="Verify Password"
                    required
                    autoFocus
                    onChange={e => setVerifyPassword(e.target.value)}
                  />
                  {passwordValidation(password, verifyPassword) &&
                    verifyPassword !== "" && (
                      <div className="text-danger">Passwords do not Match</div>
                    )}
                  <button
                    disabled={passwordValidation(password, verifyPassword)}
                    className="btn btn-primary btn-block mb-1"
                    type="submit">
                    {userReducer.isUpdating ? (
                      <>
                        <i className="fa fa-spinner fa-spin" />
                        <span> Processing...</span>
                      </>
                    ) : (
                      <>
                        <i className="fa fa-paper-plane mr-2" />
                        <span>Reset Password</span>
                      </>
                    )}
                  </button>
                </form>
              </div>
            ) : (
              <div className="card-body">
                <h5 className="ml-0 mt-4 text-center">Success!!!!</h5>
                <h6 className="mb-0 text-center">
                  Your Password Has Been Reset SuccessFully!!!
                </h6>
                <h7 className="mb-0 text-center">
                  Please Login to continue.
                  <Link to="/">Click here to go to login page</Link>
                </h7>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(({ userReducer }) => ({ userReducer }), {
  changePassword,
})(ResetPassword);
